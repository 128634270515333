import React, { useState } from 'react';

const RadioList = (props) => {
  const [selectedItem, setSelectedItem] = useState(props.options[0].value);

  const handleChange = (event) => {
    setSelectedItem(event.target.value);
  };

  return (
    <>
      <p>{props.label}</p>
      <ul
        onChange={props.onChange}
        type="text"
        id={props.name}
        value={props.value}
        className="radio-list"
      >
        {props.options.map((option) => (
          <li key={option.label}>
            <label>
              <input
                type="radio"
                name={props.name}
                value={option.value}
                checked={selectedItem === option.value}
                onChange={handleChange}
              />
              <span className="checkmark"></span>
              {option.label}
            </label>
          </li>
        ))}
      </ul>
    </>
  );
};

export default RadioList;

import React from 'react';
import RadioList from './RadioList';
import Select from './Select';
import FileInput from './FileInput';
import AdditionalContacts from './AdditionalContacts';
import PatternInput from './PatternInput';

const FormInput = (props) => {
  if (props.name === 'additionalContacts') {
    return (
      <AdditionalContacts
        onChange={props.onChange}
        name={props.name}
        value={props.value}
        label={props.label}
        fields={props.fields}
        error={props.error}
      />
    )
  };

  if (props.name === 'files') {
    return (
      <FileInput
        placeholder={props.placeholder}
        setFiles={props.setFiles}
        label={props.label}
        description={props.description}
      />
    )
  };

  if (props.type === 'select') {
    const selectOptions = props.name === 'state'
      ? props.options.map((option) => (
        <option key={option} value={option}>
          {option || 'Please choose the state*'}
        </option>
      ))
      : props.options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))

    return (
      <Select
        name={props.name}
        error={props.error}
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        options={selectOptions}
      />
    )
  };

  if (props.type === 'checkbox') {
    return (
      <RadioList
        onChange={props.onChange}
        name={props.name}
        value={props.value}
        label={props.label}
        options={props.options}
      />
    )
  };

  if (props.type === 'pattern') {
    return (
      <PatternInput
        onChange={props.onChange}
        name={props.name}
        value={props.value}
        label={props.label}
        error={props.error}
        maxLength={props.maxLength}
        required={props.required}
      />
    )
  };

  if (props.name === 'confirmEmail') {
    return (
      <input
        type="text"
        id={props.name}
        name={props.name}
        className={`input ${props.error ? "error" : ''}`}
        placeholder={`${props.label}${props.required ? '*' : ''}`}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.validateConfirmEmail}
        aria-label={props.label}
        aria-required={props.required}
        aria-invalid={props.error !== ''}
        aria-describedby={props.error}
      />
    )
  };

  return (
    <>
      <input
        type="text"
        id={props.name}
        aria-label={props.label}
        aria-required={props.required}
        aria-invalid={props.error !== ''}
        aria-describedby={props.error}
        name={props.name}
        className={`input ${props.error ? "error" : ''}`}
        placeholder={`${props.label}${props.required ? '*' : ''}`}
        value={props.value}
        onChange={props.onChange}
        maxLength={props.maxLength}
      />
    </>
  );
};

export default FormInput;

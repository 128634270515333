import React, { useState, useEffect } from 'react';
import { fields, fundraiserField, howDidYouFindOutAboutUs } from './fields';
import { registerTexts } from '../../utils/locales';
import {
  validateFormFields,
  validateSingleField,
  checkValidation,
  toBase64,
  fieldsRender
} from '../../utils/helpers';
import routes from '../../utils/routes';
import { SUCCESS_PAGE_LINK } from '../../utils/constants';
import FormInput from '../../components/Input';
import { addOrganisation } from '../../api/organisation';

const RegisterForm = () => {
  const [formFields, setFormFields] = useState(fields);
  const [files, setFiles] = useState([]);
  const [fundraiserDetails, setFundraiserDetails] = useState('');
  const [findOutAboutUs, setFindOutAboutUs] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [formError, setFormError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleAcceptTermsChange = () => {
    setIsChecked(!isChecked);
  };

  const handlefundraiserDetailsChange = (e) => {
    setFundraiserDetails(e.target.value);
  };

  const handleFindOutAboutUsChange = (e) => {
    validateSingleField(howDidYouFindOutAboutUs, e.target.value);
    setFindOutAboutUs(e.target.value);
  };

  const handleChange = (e, fieldName) => {
    const updatedFormFields = formFields.map((field) => {
      if (field.name === fieldName) {
        field.value = e.target.value;
        if (field.name !== 'confirmEmail'
        || (field.name === 'confirmEmail' && field.error !== "")) {
          validateFormFields(field, emailValue);
        }
      }
      return field;
    });

    if (fieldName === 'representativeEmail') {
      setEmailValue(e.target.value);
    }
    setFormFields(updatedFormFields);
  };

  const validateConfirmEmail = () => {
    const updatedFormFields = formFields.map((field) => {
      if (field.name === 'confirmEmail' && field.value !== "") {
        validateFormFields(field, emailValue);
      }
      return field;
    });

    setFormFields(updatedFormFields);
  }

  useEffect(() => {
    setFormFields(prevFields => {
      const updatedFormFields = prevFields.map((field) => {
        if (field.name === 'confirmEmail' && field.value !== '') {
          validateFormFields(field, emailValue)
        };
        return field;
      });
      return updatedFormFields;
    });
  }, [emailValue]);

  useEffect(() => {
    setFormFields(fields.map(field => ({ ...field, value: '' })));
    setFundraiserDetails('');
    setFindOutAboutUs('');
    window.history.pushState(null, '', routes.pathToRegister);
  }, []);

  const formSubmissionHandler = async (event) => {
    event.preventDefault();
    setFormError('');
    setIsLoading(true);

    const updatedFormFields = formFields.map((field) => validateFormFields(field, emailValue));
    const updateFindOutAboutUs = validateSingleField(howDidYouFindOutAboutUs, findOutAboutUs);

    const findOutAboutUsError = updateFindOutAboutUs.error !== "" && updateFindOutAboutUs;

    const formFieldWithError = checkValidation(updatedFormFields) || findOutAboutUsError;

    setFormFields(updatedFormFields);

    if (formFieldWithError) {
      setIsLoading(false);
      document.getElementById(formFieldWithError.name).scrollIntoView({ behavior: 'smooth' })
    }

    if (!formFieldWithError && isChecked) {
      const formData = {};
      for (const field of formFields) {
        formData[field.name] = field.name === 'additionalContacts' ? field.value : field.value.trim();
      }
      if (files.length > 0) {
        formData['files'] = await toBase64(files);
      }
      if (fundraiserDetails !== "") {
        formData['fundraiserDetails'] = fundraiserDetails;
      }
      if (findOutAboutUs !== "") {
        formData['howDidYouFindOutAboutUs'] = findOutAboutUs;
      }
      if (formData['estimatedPeopleReach'] === '') {
        formData['estimatedPeopleReach'] = '1-100';
      }

      const response = await addOrganisation(formData);

      setIsLoading(false);

      if (!response.ok) {
        const errorMessage = await response.text();

        setFormError(errorMessage || 'Form was not submitted');
        throw new Error("Network response was not ok");
      }

      setFormFields(fields.map(field => ({ ...field, value: '', error: '' })));
      setFundraiserDetails('');
      setFindOutAboutUs('');

      window.location.href = SUCCESS_PAGE_LINK;
    }
  };

  return (
    <form onSubmit={formSubmissionHandler} className="register-form" >
      <h2 className="title" id="contact-label">{registerTexts.form.title}</h2>
      <div className="form-fields" role="group" aria-labelledby="contact-label">
        {fieldsRender(formFields, handleChange, setFiles, validateConfirmEmail)}
      </div>
      <h2 className="title" id="details-label">{registerTexts.about.title}</h2>
      <div className="form-fields" role="group" aria-labelledby="details-label">
        <textarea
          id={fundraiserField.name}
          name={fundraiserField.name}
          placeholder={fundraiserField.label}
          value={fundraiserDetails}
          onChange={handlefundraiserDetailsChange}
          aria-label={fundraiserField.name}
          aria-required={false}
        />
      </div>
      <h2 className="title" id="submit-label">{registerTexts.submit.title}</h2>
      <div className="form-fields" role="group" aria-labelledby="submit-label">
        <div className="form-field">
          <FormInput
            name={howDidYouFindOutAboutUs.name}
            label={howDidYouFindOutAboutUs.label}
            value={findOutAboutUs}
            error={howDidYouFindOutAboutUs.error}
            onChange={handleFindOutAboutUsChange}
            required
          />
          {howDidYouFindOutAboutUs.error && (
            <div className="error mb-5">
              {howDidYouFindOutAboutUs.errorMessage}
            </div>
          )}
        </div>
        <div className="form-box" tabIndex="0">
          <p>{registerTexts.submit.description}</p>
        </div>
        <div className="checkbox">
          <input
            type="checkbox"
            name="acceptTerms"
            id="acceptTerms"
            checked={isChecked}
            onChange={handleAcceptTermsChange}
            aria-checked={isChecked}
            aria-required={true}
          />
          <label htmlFor="acceptTerms">
            {registerTexts.submit.checkbox}
          </label>
        </div>
      </div>
      <div className="form-actions">
        <button disabled={!isChecked || isLoading} type="submit">{registerTexts.submit.button}</button>
      </div>
      {formError !== "" && <div className="form-error">{formError}</div>}
    </form>
  );
};

export default RegisterForm;

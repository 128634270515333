export const fields = [
  {
    name: "name",
    label: "Name of Organization",
    errorMessage: "Field should contain from 1 to 75 characters",
    value: "",
    error: "",
    required: true,
    regExp: "max75Symbols",
  }, {
    name: "representativeFirstName",
    label: "Representative First Name",
    errorMessage: "Field should contain from 1 to 50 characters",
    value: "",
    error: "",
    required: true,
    halfWidth: true,
    onlyLetter: true,
    regExp: "max50Letters",
  }, {
    name: "representativeLastName",
    label: "Representative Last Name",
    errorMessage: "Field should contain from 1 to 50 characters",
    value: "",
    error: "",
    required: true,
    halfWidth: true,
    onlyLetter: true,
    regExp: "max50Letters",
  }, {
    name: "representativeEmail",
    label: "Representative Email Address",
    errorMessage: "Must be in format mail@mail.com",
    value: "",
    error: "",
    required: true,
    halfWidth: true,
    regExp: "email",
  }, {
    name: "confirmEmail",
    label: "Confirm Email Address",
    errorMessage: "Incorrect email format",
    value: "",
    error: "",
    required: true,
    halfWidth: true,
    regExp: "email",
  }, {
    type: "pattern",
    name: "phone",
    label: "Mobile Phone*",
    maxLength: 12,
    errorMessage: "Please enter valid phone number",
    value: "",
    error: "",
    required: true,
    regExp: "phone",
  }, {
    name: "additionalContacts",
    label: "Add additional contacts",
    errorMessage: "",
    value: "",
    error: "",
    required: false,
    fields: [
      {
        name: "firstName",
        label: "First Name",
        errorMessage: "Field should contain from 1 to 50 characters",
        value: "",
        error: "",
        required: false,
        onlyLetter: true,
        regExp: "max50Letters",
      }, {
        name: "lastName",
        label: "Last Name",
        errorMessage: "Field should contain from 1 to 50 characters",
        value: "",
        error: "",
        required: false,
        onlyLetter: true,
        regExp: "max50Letters",
      }, {
        name: "email",
        label: "Email",
        errorMessage: "Must be in format mail@mail.com",
        value: "",
        error: "",
        required: false,
        regExp: "email",
      }, {
        name: "phone",
        label: "Phone",
        errorMessage: "Please enter valid phone number",
        value: "",
        error: "",
        required: false,
        maxLength: 12,
        regExp: "phone",
      }
    ]
  }, {
    name: "addressLine1",
    label: "Address Line 1",
    errorMessage: "",
    value: "",
    error: "",
    required: true,
  }, {
    name: "addressLine2",
    label: "Address Line 2",
    errorMessage: "",
    value: "",
    error: "",
    required: false,
  }, {
    name: "city",
    label: "City",
    errorMessage: "Field should contain from 1 to 50 characters",
    value: "",
    error: "",
    required: true,
    halfWidth: true,
    onlyLetter: true,
    regExp: "max50Letters",
  }, {
    name: "state",
    label: "State",
    errorMessage: "",
    value: "",
    error: "",
    required: true,
    halfWidth: true,
    type: "select",
    options: [
      "", "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI",
      "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS",
      "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR",
      "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
    ]
  }, {
    type: "pattern",
    name: "zipCode",
    label: "ZIP Code*",
    errorMessage: "Field should contain only digits and symbols",
    value: "",
    error: "",
    required: true,
    regExp: "zipCode",
  }, {
    name: "websiteUrl",
    label: "Website Address",
    errorMessage: "",
    value: "",
    error: "",
    required: true,
  }, {
    type: "pattern",
    name: "taxId",
    label: "Tax ID number",
    errorMessage: "9 digits code",
    maxLength: 11,
    value: "",
    error: "",
    required: false,
    regExp: "taxId",
  }, {
    name: "files",
    placeholder: "Upload 501(c)(3) Determination Letter",
    label: "Word, Excel, PowerPoint, PDF, JPEG, and PNG files are accepted.",
    description: "While you don’t have to upload this document now, we will require it before we release any payments to your organization.",
    errorMessage: "",
    value: "",
    error: "",
    required: false,
  }, {
    name: "describe",
    label: "Please select what best describes your Organization",
    errorMessage: "",
    value: "",
    error: "",
    required: true,
    type: "select",
    options: [
      { value: "", label: "Please select what best describes your Organization*" },
      { value: "Arts, Culture and Humanities", label: "Arts, Culture and Humanities" },
      { value: "Environment / Animals", label: "Environment / Animals" },
      { value: "Foundation", label: "Foundation" },
      { value: "Health", label: "Health" },
      { value: "Human Services", label: "Human Services" },
      { value: "Religious", label: "Religious" },
      { value: "Other", label: "Other" },
    ]
  }, {
    name: "estimatedPeopleReach",
    label: "Estimated number of people you’d be reaching*",
    errorMessage: "",
    value: "",
    error: "",
    required: false,
    type: "checkbox",
    options: [
      { value: "1-100", label: "1-100" },
      { value: "100-250", label: "100-250" },
      { value: "250-500", label: "250-500" },
      { value: "500-1000", label: "500-1000" },
      { value: "1000-5000", label: "1000-5000" },
      { value: "5000+", label: "5000+" },
    ]
  },
];

export const fundraiserField = {
  name: "fundraiserDetails",
  label: "Fundraiser objectives/details",
  errorMessage: "",
  value: "",
  error: "",
  required: false,
};

export const howDidYouFindOutAboutUs = {
  name: "howDidYouFindOutAboutUs",
  label: "How did you find out about us?",
  errorMessage: "Field should contain from 1 to 50 characters",
  value: "",
  error: "",
  required: true,
};

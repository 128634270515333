export const addOrganisation = async (formData) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_PATH}/organisations`, {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json"
      }
    });

    return response;
  } catch (error) {
    console.error("Error submitting form:", error);
  }
};

import React, { useEffect } from 'react';
import Routes from './routes';
import Layout from './sections/Layout';

function App() {
  useEffect(() => {
    if (process.env.REACT_APP_GA4_MEASUREMENT_ID) {
      const script1 = document.createElement('script');
      script1.async = true;
      script1.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA4_MEASUREMENT_ID}`;
      document.head.appendChild(script1);

      const script2 = document.createElement('script');
      script2.textContent = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${process.env.REACT_APP_GA4_MEASUREMENT_ID}');
      `;
      document.head.appendChild(script2);

      return () => {
        document.head.removeChild(script1);
        document.head.removeChild(script2);
      };
    }
  }, []);

  return (
    <div className='app'>
      <Layout>
        <Routes />
      </Layout>
    </div>
  );
}

export default App;

import instagramIcon from './instagram-icon.svg';
import facebookIcon from './facebook-icon.svg';
import linkedInIcon from './linkedin-icon.svg';

export const icons = [
  {
    image: facebookIcon,
    altText: 'facebook',
    href: 'https://www.facebook.com/people/Givafifth/100082649381794/',
  }, {
    image: instagramIcon,
    altText: 'instagram',
    href: 'https://www.instagram.com/givafifth/',
  }, {
    image: linkedInIcon,
    altText: 'linkedIn',
    href: 'https://www.linkedin.com/company/givafifth/',
  }
]

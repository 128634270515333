import React from 'react';
import { SUPPORT_EMAIL } from '../../utils/constants';

const TextContainer = (props) => {
  return (
    <div className='text-container'>
      <h1 className='title'>{props.title}</h1>
      <p className='description'>
        {props.description}
        <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
      </p>
    </div>
  );
};

export default TextContainer;
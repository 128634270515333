export const registerTexts = {
  descriptionTitle: 'REGISTER ACCOUNT',
  description: `Givafifth has been designed to help 501(c)(3) organizations grow through charitable donations made through the purchase of our products.  

  The first step is to fill out this simple form to receive your fundraiser code. Once you receive that, you can use our marketing tools to help you succeed! 
  
  If you don’t have your Tax ID# handy, and/or need time to look for your 501(c)(3) Determination Letter, no worries (we can collect them from you later). 
  
  If you are not a 501(c)(3) and are looking to raise funds using our platform, please send an email to `,
  form: {
    title: 'ABOUT YOU AND THE ORGANIZATION YOU ARE REGISTERING',
  },
  about: {
    title: 'ABOUT YOUR FUNDRAISER',
  },
  submit: {
    title: 'SUBMIT YOUR INFORMATION',
    description: `AGENCY AGREEMENT
 
    This AGENCY AGREEMENT (“Agreement”)is entered into between a 501(c)3 registered non-profit organization ("Principal") and, G5 GIVAFIFTH LLC, a New Jersey limited liability company ("Agent") with a principal place of business at 95 W. Main St Suite 5-335, Chester NJ 07930(Principal and Agent are each a “Party” and collectively the Parties”  This Agreement will be effective as of the date at which the Principal indicates its acceptance of this Agreement during the registration process to be listed on the Agent's website.
    WHEREAS, Principal needs the assistance of Agent to collect and administer charitable donations made to Principal, as well as to distribute such charitable donations as directed by the individuals and/or entities making such charitable donations ("Donor”) subject to the terms herein (the services described herein and set forth in more detail in paragraph 1 below, shall be referred to as the “Services”).
    WHEREAS, Principal wishes to appoint Agent as its exclusive agent to represent Principal with respect to the Services;   
    WHEREAS, Agent accepts the appointment to serve as Principal’s non-exclusive agent with respect to the Services, subject to the terms and conditions of this Agreement; and
    NOW, THEREFORE, in consideration of the promises and mutual covenants contained herein, the Parties intending to be legally bound hereby agree to the following terms: 
    1.	Agent, Objectives, Responsibilities and Duties.  Principal appoints Agent to perform the Services for and on behalf of the Principal. Services include the collection of donations from donors as a result of their purchases on the Agent’s website.  Upon registration, Principal shall receive a unique fundraiser code.  At checkout on the Agent’s website, donors will enter the Principal’s fundraiser code.  Agent shall collect Twenty (20%) percent of each product purchased with the Principal’s fundraiser code,  and distribute to the Principal on a monthly basis.  Any donations collected by Agent shall be deposited in an operating account at the point of sale, and transferred to a separate and segregated bank account, and such donations shall not be commingled with any other funds of Agent.   Agent shall be responsible for providing funds donated by the particular donor to the charity as directed by such donor, subject to the terms herein, except in the case where Principal has not properly completed the registration process as defined in point 17
    
    2.	Relationship Created.  Agent will perform all Services strictly as an independent contractor and IC will not be considered an employee of Company. Nothing in this Agreement shall be interpreted or construed as creating, establishing, continuing, or reestablishing any employer-employee.  Agent shall be solely responsible for supervising its own activities.  Agent, in Agent’s sole discretion, shall determine the hours during, and the manner by, which Agent will perform the Services pursuant to this Agreement according to Agent’s own routines and schedules. Principal shall not control the instrumentalities, details, or means by which Agent achieves the results for which Agent is retained under this Agreement other than the right to specify the general services that the Principal engages Agent to perform and to require that the performance of such services be, at all times, in strict accordance with the law, and the reasonable and safe methods of practices of Agent’s profession and with all recognized professional ethics and customs. Agent shall be responsible for obtaining and maintaining Agent’s own office equipment, including but not limited to: computers and software, phones, Internet connectivity and general office supplies necessary to perform the work under this Agreement. Principal shall have no obligation to make any payment or to provide any assistance to Agent with respect to Agent’s operation of its business, including, but not limited to compensation of Agent’s employees, rental of office space, and acquisition of office supplies.  Agent shall provide all equipment and materials.  Principal shall not have the right to require Agent to do anything, which would jeopardize the relationship of independent contractor between Principal and Agent.  
    
    3.	Compensation.  Agent shall receive a processing fee payment of 3.75% of the total donation amount and a platform fee payment of 3.75% of the total donation amount, as compensation for the Services detailed herein. Principal will receive donations net of compensation at regular intervals, but no less than quarterly, by Agent.  Agent is responsible for paying all of Agent’s taxes including but not limited to United States Federal and any and all State Income Tax, Social Security Tax, Workman’s Compensation Tax, Federal Insurance Contribution Act, Federal Unemployment Tax Act, and all other payroll taxes and all State and/or local taxes.  Neither Federal, nor State, nor local income tax of any kind shall be withheld or paid by Principal on behalf of Agent or the employees of Agent. Agent will be required to file corporate tax returns and to pay taxes in accordance with all provisions of applicable Federal and State law.
    
    4.	Purchase Price Breakdown.  Donors of Principal shall purchase Products from Agent.  Agent shall disburse Twenty (20%) percent of the proceeds to Principal as a charitable donation on a monthly basis as follows:
    
    a.	Agent shall disburse 20% of all additional purchases to Principal as a charitable donation on a monthly basis after deducting the 7.5% of the total donation amount, as described in paragraph 3 above.
    b.	As an example, if Agent receives $1500.00 from items purchased through donors of Principal during the month of January, the total amount that would be disbursed to Principal is $277.5.00 ($300.00 (20% of $1500.00) - $22.50 (7.5% x $300))
    c.	As an example, for the additional donation amount, if Agent receives and additional donation of $100 over and above the $1500, the total amount of the additional donation amount that would be disbursed to Principal is $92.50 ($100 - $7.50 (7.5% of $100))
    
    5.	Trademarks/Intellectual Property.  Principal shall not have the right to use the Marks other than for advertising only in performance of this Agreement and shall not use any confusingly similar trademarks or service marks in connection with any goods or services. Principal shall at all times use each of the Marks in its proper trademark sense, acknowledging that it is the registered trademark of Agent and shall provide appropriate (as determined by Agent) trademark notices with respect thereto. Principal shall comply with all guidelines issued by Agent from time to time for use of the Marks.  Marks means the name givafifth and includes but is not limited to the stylized design logo, which are the registered trademarks of Agent, and any other trademarks, whether or not registered, which Agent may use in connection with Products (or any other Products of Agent) from time to time.
    
    6.	Reporting and Tax Receipts. Principal may receive donations through the Agent Website and will be provided with the name, email address and amount donated to allow the Principal to provide tax receipts to donors. Agent does not issue tax receipts, nor does it represent or warrant that donations will be tax deductible under the law. Agent does not provide legal or tax advice, and nothing contained herein should be construed as such.
    
    7.	Term and Termination.  The Agreement shall continue in full force and effect from the date of this Agreement until the date, upon not less than thirty (30) days written notice, that either Principal or Agent terminates this Agreement.  
    
    8.	Notices.  Any notice, demand, or request required or permitted to be given hereunder shall be in writing and shall be deemed effective on personal delivery or seventy-two (72) hours after having been deposited in the United States mail, postage prepaid, registered or certified, and addressed to the addressee, at his or its main office, as set forth in the Preamble above.  Any Party may change his or her address for purposes of this Agreement by written notice given in accordance herewith.
    9.	Financial Information.  Agent will require billing and payment information such as credit and debit card information when a Donor creates an account for the purposes of purchasing Products and donating to Principal.  However, all payment transactions entered into on the Website are processed by a third-party payment processor, currently Shopify (“Payment Processor”). Donors will provide payment information to the Payment Processor, and that Payment Processor’s Privacy Policy and Terms and Conditions of Use will govern use and disclosure of that information.  We encourage Users to carefully read the Payment Processor’s Privacy Policy and Terms and Conditions of Use which can be accessed from its website. We do not use or store financial information. Agent strictly disclaims any and all liability regarding a breach of any financial information. 
    
    
    10.	Fundraiser and Commercial Co-Venture Rules.  Principal agrees that Agent is providing e-payment processing services under this Agreement and Principal is NOT seeking professional fund-raising services or attempting to enter into a commercial co-venture.  It is the understanding of both parties that any charitable donations made to Principal are not being made as a result of a charitable solicitation or appeal made by Agent.  Principal agrees not to take any contrary position on any filing with any governmental agency, except as required by law and following notice to Agent of such contrary position.  
    
    11.	Limitation of Liability.
    
    a.	Disclaimer.  EXCEPT AS EXPRESSLY PROVIDED IN THIS AGREEMENT, AGENT MAKES NO WARRANTY, EXPRESSED OR IMPLIED, WITH RESPECT TO THIS AGREEMENT, THE PRODUCTS OR SERVICES RENDERED HEREUNDER, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE.
    b.	Total Liability. Agent’s liability hereunder for damages derived or caused from the negligence of the Agent for any claim arising out of this Agreement or the brokerage of any Products or performance of any Services hereunder shall not exceed the total amount paid to Agent for those Products or Services.
    c.	Indemnification. Agent shall have no liability to Principal for Products brokered or procured under this Agreement. Agent shall have no obligation or liability to perform any duty other than the Services described in this Agreement.  
    
    12.	Governing Law and Jurisdiction.  This Agreement shall be construed and enforced in accordance with the laws of the State of New Jersey.  Any judicial proceeding brought against any of the Parties to this Agreement on account of any dispute arising out of this Agreement, or any matter related hereto, shall be brought in the state courts of County of Morris and the State of New Jersey, and, by execution and delivery of this Agreement, each of the parties to this Agreement submits and consents to the exclusive jurisdiction of such courts, and irrevocably agrees to be bound by any judgment rendered thereby in connection with this Agreement. If either Party shall institute an action against the other Party for breach of this contract, the unsuccessful Party shall pay court costs and reasonable attorney's fees to the successful Party.
    
    13.	Captions.  Captions and headings used herein are for convenience only and shall not be used in construing or interpreting this Agreement.
    
    14.	No Third-Party Beneficiaries.  Each of the provisions of this Agreement is for the sole and exclusive benefit of the Parties hereto, respectively, as their interests appear, and shall not be deemed for the benefit of any other person.   
    
    15.	Confidential Information.  The Principal and Agent acknowledge the confidential and proprietary nature of the Confidential Information, agree to hold and keep the same as provided in this Agreement, and otherwise agree to each and every restriction and obligation in this Agreement. The term “Confidential Information” means and includes any and all:
    a.	Trade secrets concerning the business and affairs of either party, product specifications, data, know-how, formulae, compositions, processes, designs, sketches, photographs, graphs, drawings, samples, inventions and ideas, past current, and planned research and development, current and planned manufacturing or distribution methods and processes, current and anticipated Principal requirements, price lists, market studies, business plans, computer software and programs, structures and architectures (and related processes, formulae, composition, improvements, devices, know-how, inventions, discoveries, concepts, ideas, designs, methods and information), and any other information, however documented, that is a trade secret within the meaning of New Jersey law;
    b.	non-public personal information concerning the Principal’s or Agent’s Principals, which information shall include, without limitation, non-public personal financial information and non-public health information concerning such Principal Principals; and
    c.	information concerning the business and affairs of each party (which includes historical financial statements, financial projections and budgets, historical and projected sales, capital spending budgets and plans, the names and backgrounds of key personnel, personnel training techniques and materials, however documented, that has been or may hereafter be provided or shown to the receiving party or its Representatives (the Principal’s directors, officers, employees, agents, consultants, advisors, or other representatives including legal counsel, accountants and financial advisors) or is otherwise obtained from review of documents or property or discussion with the disclosing party or its Representatives by the receiving party or its Representatives (including current or prospective financing sources) irrespective of the form of the communication, and also includes all notes, analyses, compilations, studies, summaries, and other material prepared by the receiving party or its Representatives containing or based, in whole or in part, on any information included in the foregoing. In the case of trade secrets, both parties hereby waive any requirement that the party disclosing the trade secret submit proof of the economic value of any trade secret or post a bond or other security
    The Confidential Information is being furnished without liability on the part of either party or any of their respective Representatives. This Agreement creates no obligation on the part of either party to furnish any information to the other party. However, the actual dissemination of Confidential Information pursuant to the terms of this Agreement shall serve as consideration for the covenants made hereunder.
    Each party agrees on its own behalf, and agrees to cause its Representatives to acknowledge that: All of the foregoing obligations and restrictions do not apply to that part of the Confidential Information that the receiving party demonstrates (a) was or becomes generally available to the public other than as a result of a disclosure by the receiving party or the receiving party’s Representatives or (b) was available, or becomes available, to the receiving party on a non-confidential basis prior to its disclosure to the receiving party by the disclosing party or a disclosing party Representative, but only if (i) the source of such information is not bound by the Confidentiality Agreement with disclosing party or is not otherwise prohibited from transmitting the information to the receiving party or the receiving party’s Representatives by a contractual, legal, fiduciary, or other obligation and (ii) the receiving party provides disclosing party with written notice of such prior possession either (A) prior to the execution and delivery of this Agreement or (B) if the receiving party later becomes aware of (through disclosure to the receiving party or otherwise through the receiving party’s work on the engagement) any aspect of the Confidential Information of which the receiving party had prior possession, promptly upon the receiving party becoming aware of such aspect.
    In the event that the parties or their respective Representatives, is, in the opinion of legal counsel, required by legal process, law or regulation to disclose any portion of the Confidential Information provided to it, such party shall provide the other party with prompt written notice of such requirement as far in advance of the proposed disclosure as possible so that the other party (at its expense) may either seek a protective order or other appropriate remedy which is necessary to protect its interests or waive compliance with the non-disclosure provisions of this Agreement to the extent necessary (provided that one or the other be done). Each of the parties and their respective Representatives shall cooperate in all reasonable respects with each other in seeking to prevent or limit disclosure and, in the event a protective order or other remedy is not obtained, the party being required to make disclosures will limit the disclosure to the information actually required to be disclosed, provided that such party shall not be required to incur any out-of-pocket costs in complying with this paragraph.
    Each of the parties shall indemnify and hold the other party and its Representatives harmless from any damages, loss, cost, or liability (including reasonable legal fees and the cost of enforcing this indemnity) arising out of or resulting from any unauthorized use or disclosure by the receiving party or its Representatives of the Confidential Information or other violation of this Agreement. In addition, because an award of money damages (whether pursuant to the foregoing sentence or otherwise) would be inadequate for any breach of this Agreement by the receiving party or its Representatives and any such breach would cause the disclosing party irreparable harm, the receiving party also agrees that, in the event of any breach or threatened breach of this Agreement, the disclosing party will also be entitled, without the requirement of posting a bond or other security, to equitable, relief, including injunctive relief and specific performance. Such remedies will not be the exclusive remedies for any breach of this Agreement but will be in addition to all other remedies available at law or in equity to the disclosing party.
    
    16.	Validity.  The failure of any Party to enforce at any time any of the provisions of this Agreement shall in no way be deemed a waiver of any such provision, nor in any way affect the validity of this Agreement or any part thereof.   
    
    17.	Successors and Assigns.  This Agreement shall be binding and inure to the benefit of the successors and assigns of the Parties hereto.    
    
    18.	Force Majeure.  Neither Party shall be liable or responsible to the other Party nor be deemed to have defaulted under or breached the Agreement for failure or delay in fulfilling or performing any obligation under this Agreement if the delay or failure is caused by (a) governmental action, laws, orders, directions, regulations, or requests, or (b) as a result of events, such as war, acts of public enemies, terrorism, fires, floods, pandemic, endemic, acts of God or any causes beyond the reasonable control of such Party. The delaying Party shall be entitled to a reasonable extension of time for the fulfillment of such obligations. The Parties shall mutually seek a resolution of the delay or the failure to perform.
    
    
    19.	Authority to Contract. Each Party represents that it has the full power and authority to execute, deliver and perform this Agreement and to convey the rights herein conveyed. Each Party further represents that this Agreement does not conflict with or violate any agreement, covenant or restriction by or to which such Party or its assets is bound. Each Party further represents that it has not entered into any agreements that would conflict with its obligations hereunder or would render it incapable of satisfactorily performing hereunder.
    
    20.	Entire Agreement.  This Agreement constitutes the entire contract among the Parties hereto with respect to the subject matter hereof, superseding and replacing all prior agreements, discussions and communications, and no party hereto shall be bound by any communication on the subject matter hereof unless such is in writing signed by any necessary party thereto and bears a date subsequent to the date hereof.  No amendment, change or modification hereof or supplement hereto shall be valid or binding unless same is in writing and signed/acknowledged electronically, by Agent and Principal. No waiver of any provision of this Agreement shall be valid unless the same is in writing and signed by the party against whom such waiver is sought to be enforced.
    
    21.	Severability.  Any provision of this Agreement that is prohibited or unenforceable shall not invalidate any of the other provisions hereof and such other provisions shall remain in full force and effect.  
    
    22.	Survival.  Any provision of this Agreement that expressly or by implication is intended to survive termination, regardless of the date, cause or manner of such termination, and including but not limited to rights of action accruing prior to termination and payment obligations, will survive such termination and will continue in full force and effect.
    
    23.	Construction.  . This Agreement shall not be construed against the Party preparing it, but shall be construed as if it was jointly prepared and negotiated by the Parties and all persons and entities affected hereby.  Any ambiguity or uncertainty shall not be interpreted against any person or entity.  Neither Party was coerced, under undue influence, nor in any way forced to enter into this Agreement and both Parties had the opportunity to have this Agreement reviewed by counsel of their own choosing.  
    
    24.	Counterparts.  This Agreement may be executed in two or more counterparts, and by the different Parties hereto on separate counterparts, each of which shall be deemed an original, but all of which shall constitute one and the same document. The Parties agree that the delivery of a copy of a counterpart executed by facsimile signature or by scanned image of such signed counterpart (for example but not limitation, in a .pdf format) shall constitute effective delivery of same and such facsimile or scanned signature shall be treated as having the same force and effect as the original thereof.
    
    25.	Legitimacy and Confirmation of 501(c)(3) Status.  Principal agrees to submit all required information to Agent, including but not limited to tax ID and 501(c3) Determination Form to allow Agent to verify Principal’s tax status, and certifies this information to be accurate and representative of their legal tax status.  If at any time Agent’s 501c3 status changes, Agent is responsible for informing Principal before receiving future donations.
    
    26.	Registration. If Principal does not fully complete all the requirements of the registration process, including but not limited to 501(c)3 status, bank information, and any documents deemed required, within 3 months of the first donation, any donation collected on behalf of Principal will be transferred to the Givafifth Foundation and be forfeited by Principal. In such an event, Agent will notify all shoppers who have received an order confirmation for purchases entered with the Principal’s fundraiser code that the donation has been transferred to the Givafifth Foundation and can as such be used as an updated order confirmation.    
    `,
    checkbox: 'Please check if you have read and agreed to the disclosure.',
    button: 'SUBMIT',
  }
};

export const errorPage = {
  title: '404 Error',
  description: 'Page not found.',
};

import React, { useState } from 'react';

const Select = ({ name, label, value, error, options, onChange }) => {
  const [selectedValue, setSelectedValue] = useState('');
  const [otherValue, setOtherValue] = useState('');

  const handleChange = (event, setValue) => {
    const selectedValue = event.target.value;
    const newValue = selectedValue === 'Other' ? otherValue : selectedValue;

    setValue(selectedValue);
    onChange({ ...event, target: { ...event.target, value: newValue } });
  };

  return (
    <>
      <select
        name={name}
        id={name}
        className={`input ${error ? 'error' : ''} select`}
        placeholder={label}
        aria-label={label}
        aria-required={true}
        aria-invalid={error !== ''}
        aria-describedby={error}
        value={selectedValue}
        onChange={(e) => handleChange(e, setSelectedValue)}
      >
        {options}
      </select>
      {selectedValue === 'Other' && (
        <input
          type="text"
          name={`${name}-other`}
          id={`${name}-other`}
          className={`input ${error ? 'error' : ''} mt-10`}
          placeholder="Other value*"
          value={otherValue}
          onChange={(e) => handleChange(e, setOtherValue)}
          aria-label="Other"
          aria-required={true}
          aria-invalid={error !== ''}
          aria-describedby={error}
        />
      )}
    </>
  );
};

export default Select;

import React from 'react';
import heroImage from '../../assets/hero-banner.png';

const Hero = () => {
  return (
    <div className='hero'>
      <img src={heroImage} alt="hero-banner" />
    </div>
  );
};

export default Hero;

import FormInput from "../components/Input";
import { FAQ_LINK, POLICY_LINK, AGREEMENT_LINK } from './constants';

export const quickLinks = [
  {
    text: 'FAQ',
    href: FAQ_LINK,
  }, {
    text: 'Privacy Policy',
    href: POLICY_LINK,
  }, {
    text: 'Agency Agreement',
    href: AGREEMENT_LINK,
  }
];

export const toBase64 = (files) => {
  const getBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  return Promise.all(
    Object.keys(files).map((file) => getBase64(files[file])),
  );
};

export const formRegExp = {
  onlyLetters: /^[A-Za-z\s]+$/,
  max50Symbols: /^[\w\d\s!"#$%&'()*+,\-./:;<=>?@[\]\\^_`{|}~]{1,50}$/,
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  max50Letters: /^[a-zA-Z\s]{1,50}$/,
  phone: /^\+1\d{10}$/,
  zipCode:/^[\d\s!"#$%&'()*+,\-./:;<=>?@[\]\\^_`{|}~]+$/,
  taxId: /^\d{3}-\d{2}-\d{4}$/,
  max75Symbols: /^[\w\d\s!"#$%&'()*+,\-./:;<=>?@[\]\\^_`{|}~]{1,75}$/,
};

export const validateFormFields = (field, email) => {
  if (field.required && field.value.trim() === "") {
    field.error = `Can't be empty`;
  } else if (field.onlyLetter && field.value.trim() !== "" && !field.value.match(formRegExp.onlyLetters)) {
    field.error = 'Field should contain only letters';
  } else if (field.name === 'confirmEmail' && field.value.trim() !== email.trim()) {
    field.error = "Emails don't match";
  } else if (field.value !== '' && formRegExp[field.regExp]) {
    field.value.trim().match(formRegExp[field.regExp]) ? field.error = "" : field.error = field.errorMessage;
  } else {
    field.error = "";
  }
  return field;
}

export const validateSingleField = (field, value) => {
  if (!value.match(formRegExp.max50Symbols)) {
    field.error = field.errorMessage;
  } else {
    field.error = "";
  }
  return field;
}

export const checkValidation = (fields) => fields
  .find((field) => field.name === 'additionalContacts'
    ? field.value === 'error'
    : field.error !== '');

export const fieldsRender = (fields, handleChange, setFiles, validateConfirmEmail) => fields
  .map((field) => (
    <div key={field.name} className={`form-field ${field.halfWidth ? "half-width" : ''}`}>
      <FormInput
        name={field.name}
        error={field.error}
        label={field.label}
        value={field.value}
        type={field.type}
        placeholder={field.placeholder}
        options={field.options}
        fields={field.fields}
        maxLength={field.maxLength}
        onChange={(e) => handleChange(e, field.name)}
        setFiles={setFiles}
        validateConfirmEmail={validateConfirmEmail}
        description={field.description}
        required={field.required}
      />
      {field.error && <div className="error">{field.error}</div>}
    </div>
  ))

export const formatTaxNumber = (value) => {
  let numericalValue = value.replace(/\D/g, '').slice(0, 9);

  if (numericalValue.length > 3) {
    numericalValue = `${numericalValue.slice(0, 3)}-${numericalValue.slice(3)}`;
  }
  if (numericalValue.length > 6) {
    numericalValue = `${numericalValue.slice(0, 6)}-${numericalValue.slice(6)}`;
  }
  return numericalValue;
}

export const formatPhoneNumber = (value) => {
  let phoneValue = value.replace(/\D/g, "").slice(0, 11);

  if (phoneValue.length === 1 && !value.startsWith("+1")) {
    phoneValue = "+1";
  } else if (phoneValue.length > 0) {
    phoneValue = "+" + phoneValue.slice(0);
  } 
  return phoneValue;
}

export const formatZipCode = (value) => value.replace(/[a-zA-Z]/g, "").slice(0, 20);

export const fieldsObj = (fieldsArrays) => fieldsArrays.reduce((acc, fieldsArray) => {
  const obj = {};
  fieldsArray.fields.forEach(field => {
    obj[field.name] = field?.value;
  });
  acc.push(obj);
  return acc;
}, []);

export const generateRandomId = () => {
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let id = "";
  for (let i = 0; i < 8; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    id += charset.charAt(randomIndex);
  }
  return id;
}

export const mimeTypes = {
  '.doc': 'application/msword',
  '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.xls': 'application/vnd.ms-excel',
  '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.ppt': 'application/vnd.ms-powerpoint',
  '.pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  '.pdf': 'application/pdf',
  '.jpeg': 'image/jpeg',
  '.jpg': 'image/jpeg',
  '.png': 'image/png',
}

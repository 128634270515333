import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ErrorPage from '../pages/ErrorPage';
import RegisterPage from '../pages/RegisterPage';

import routes from '../utils/routes';
const { pathToRegister, pathToError } = routes;

function App() {
  return (
    <Routes>
      <Route path={pathToError} element={<ErrorPage />} />
      <Route path={pathToRegister} exact element={<RegisterPage />} />
    </Routes>
  );
}

export default App;

const s3FolderLink = process.env.REACT_APP_AWS_REGION === 'us-east-1'
  ? `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.amazonaws.com/${process.env.REACT_APP_S3_BUCKET_FOLDER}`
  : `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${process.env.REACT_APP_S3_BUCKET_FOLDER}`;

export const FAQ_LINK = `${s3FolderLink}/${process.env.REACT_APP_S3_BUCKET_FILE_FAQ}`;

export const POLICY_LINK = `${s3FolderLink}/${process.env.REACT_APP_S3_BUCKET_FILE_POLICY}`;

export const AGREEMENT_LINK = `${s3FolderLink}/${process.env.REACT_APP_S3_BUCKET_FILE_AGREEMENT}`;

export const TEAM_EMAIL = process.env.REACT_APP_TEAM_EMAIL;

export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

export const SUCCESS_PAGE_LINK = 'https://www.givafifth.com/pages/registration-success';

export const MAX_FILES_COUNT = 3;

export const MAX_FILE_SIZE = 5 * 1024 * 1024;

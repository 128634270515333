import React, { useState } from 'react';
import { formatTaxNumber, formatZipCode, formatPhoneNumber } from '../utils/helpers';

const PatternInput = (props) => {
  const [value, setValue] = useState('');

  const formatValue = (valueName, value) => {
    switch (valueName) {
      case 'phone':
        return formatPhoneNumber(value);
      case 'taxId':
        return formatTaxNumber(value);
      default:
        return formatZipCode(value);
    }
  }

  const handleValueChange = (event) => {
    const formattedValue = formatValue(props.name, event.target.value);

    if (value.startsWith("+1") && !formattedValue.startsWith("+1")) {
      setValue(value);
      return;
    }

    setValue(formattedValue);
    props.onChange({ ...event, target: { ...event.target, value: formattedValue } })
  }

  const handleFieldFocus = () => {
    if (props.name === 'phone' && !value.startsWith("+1")) {
      setValue("+1");
    }
  };

  return (
    <input
      type="text"
      id={props.name}
      name={props.name}
      value={value}
      placeholder={props.label}
      className={`input ${props.error ? "error" : ''}`}
      onChange={handleValueChange}
      onFocus={handleFieldFocus}
      maxLength={props.maxLength}
      aria-label={props.label}
      aria-required={props.required}
      aria-invalid={props.error !== ''}
      aria-describedby={props.error}
    />
  );
}

export default PatternInput;

import React from 'react';
import IconsList from '../../components/IconsList';
import { quickLinks } from '../../utils/helpers';
import { icons } from '../../assets/icons';
import { TEAM_EMAIL } from '../../utils/constants';

const Footer = () => {
  const quickLinksRender = quickLinks.map((link) => (
    <div key={link.text} className="quick-link">
      <a href={link.href} target="_blank" rel="noreferrer">{link.text}</a>
    </div>
  ));

  return (
    <footer className="footer" id="footer" aria-label="footer">
      <div>
        <p>Social Media</p>
        <div className="footer-icons">
          <IconsList icons={icons} />
        </div>
      </div>
      <div>
        <p>Quick Links</p>
        <div className="quick-links">
          {quickLinksRender}
        </div>
      </div>
      <div className="need-help">
        <p>Need Help?</p>
        <p className="description">
          Please feel free to contact our team via <a href={`mailto:${TEAM_EMAIL}`}>{TEAM_EMAIL}</a> if you have any questions or have any issues registering.
        </p>
      </div>
    </footer>
  );
};

export default Footer;

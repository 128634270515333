import React, { useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import {
  fieldsObj,
  generateRandomId,
  validateFormFields,
  checkValidation,
  formatPhoneNumber,
} from '../utils/helpers';

const AdditionalContacts = (props) => {
  const [additionalContacts, setAdditionalContacts] = useState([]);

  const handleClick = () => {
    if (additionalContacts.length < 10) {
      const fieldsClone = cloneDeep(props.fields);
      const newAdditionalContact = { id: generateRandomId(), fields: fieldsClone }

      setAdditionalContacts([...additionalContacts, newAdditionalContact]);
    }
  };

  const handleAdditionalContactDelete = (addContactId, event) => {
    const updatedAddContacts = additionalContacts.filter((addContact) => addContact.id !== addContactId);
    setAdditionalContacts(updatedAddContacts);

    const additionalContactsData = fieldsObj(updatedAddContacts);
    props.onChange({ ...event, target: { ...event.target, value: additionalContactsData } });
  };

  const handleChange = (id, event) => {
    const updatedAddContacts = [...additionalContacts];

    const additionalContactField = updatedAddContacts.reduce((result, additionalContact) => {
      if (additionalContact.id === id) {
        result = additionalContact.fields.find((f) => f.name === event.target.name);
      }
      return result;
    }, null);

    if (additionalContactField.name === 'phone') {
      const formattedPhoneNumber = formatPhoneNumber(event.target.value);

      if (additionalContactField.value.length > 2
        && formattedPhoneNumber.length > 0
        && !formattedPhoneNumber.startsWith("+")) {
        return;
      }

      additionalContactField.value = formattedPhoneNumber;
    } else {
      additionalContactField.value = event.target.value;
    }

    validateFormFields(additionalContactField);
    setAdditionalContacts(updatedAddContacts);

    const addContactsErrors = updatedAddContacts.reduce((errors, updatedAddContact) => {
      const contactErrors = checkValidation(updatedAddContact.fields);
      if (contactErrors) {
        errors.push(contactErrors);
      }
      return errors;
    }, []);

    const additionalContactsData = addContactsErrors.length > 0
      ? 'error'
      : fieldsObj(updatedAddContacts);

    props.onChange({ ...event, target: { ...event.target, value: additionalContactsData } });
  }

  const handleFieldFocus = (id, event) => {
    const updatedAddContacts = [...additionalContacts];
    if (event.target.name === 'phone' && event.target.value === '') {
      const phoneField = updatedAddContacts.reduce((result, additionalContact) => {
        if (additionalContact.id === id) {
          result = additionalContact.fields.find((f) => f.name === 'phone');
        }
        return result;
      }, null);

      phoneField.value = "+1";
    }

    setAdditionalContacts(updatedAddContacts);
  }

  const renderFields = () => (
    additionalContacts.map((addContact) => (
      <div key={addContact.id}>
        <button
          type="button"
          onClick={(event) => handleAdditionalContactDelete(addContact.id, event)}
          className="delete-contacts"
          aria-label="Delete additional contact"
        />
        {addContact.fields.map((field) => (
          <div key={`${addContact.id}-${field.label}`}>
            <input
              type="text"
              id={`${addContact.id}-${field.name}`}
              className={`input ${field.error ? "error" : ''} mt-5 mb-5`}
              placeholder={field.label}
              value={field.value}
              name={field.name}
              onChange={(event) => handleChange(addContact.id, event)}
              onFocus={(event) => handleFieldFocus(addContact.id, event)}
              aria-label={field.label}
              aria-required={false}
              aria-invalid={field.error !== ''}
              aria-describedby={field.error}
            />
            {field.error && <div className="error">{field.error}</div>}
          </div>
        ))}
      </div>
    ))
  )

  return (
    <>
      <div id={props.name}>{renderFields()}</div>
      <button
        type="button"
        onClick={handleClick}
        className="add-contacts"
      >
        {props.label}
      </button>
    </>
  );
};

export default AdditionalContacts;

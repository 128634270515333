import React from 'react';
import Hero from '../../sections/Hero';
import TextContainer from '../../sections/TextContainer';
import RegisterForm from '../../sections/RegisterForm';

import { registerTexts } from '../../utils/locales';

const RegisterPage = () => {
  return (
    <div>
      <Hero />
      <TextContainer title={registerTexts.descriptionTitle} description={registerTexts.description} />
      <RegisterForm />
    </div>
  );
};

export default RegisterPage;

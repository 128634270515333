import React from 'react';
import { errorPage } from '../../utils/locales'

const ErrorPage = () => {
  return (
    <div>
      <h1>{errorPage.title}</h1>
      <p>{errorPage.description}</p>
    </div>
  );
};

export default ErrorPage;
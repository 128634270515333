import React from 'react';
import IconsList from '../../components/IconsList';
import headerImage from '../../assets/logo.svg';
import { icons } from '../../assets/icons';

const Header = () => {
  return (
    <header className="header" aria-label="header">
      <div className="header-content">
        <div className="header-icons">
          <IconsList icons={icons} />
        </div>
        <div className="header-logo">
          <a href={process.env.REACT_APP_WEBSITE_URL} rel="noopener noreferrer" aria-label="Visit Givafifth Home Page">
            <img src={headerImage} alt="logo" />
          </a>
        </div>
      </div>
      <div className="header-line" />
    </header>
  );
};

export default Header;

import React, { useState } from 'react';
import { mimeTypes } from '../utils/helpers';
import { MAX_FILES_COUNT, MAX_FILE_SIZE } from '../utils/constants';

const FileInput = (props) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadDisabled, setUploadDisabled] = useState(false);

  const handleUploadFiles = files => {
    const uploaded = [...uploadedFiles];

    const isLimitExceeded = files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);

        if (file.size > MAX_FILE_SIZE) {
          alert(`File "${file.name}" exceeds the maximum size of 5mb`);
          setUploadDisabled(false);
          return true;
        }

        if (uploaded.length === MAX_FILES_COUNT) {
          setUploadDisabled(true)
        };
        
        if (uploaded.length > MAX_FILES_COUNT) {
          alert(`You can only add a maximum of ${MAX_FILES_COUNT} files`);
          setUploadDisabled(false);
          return true;
        }
      }
      return false;
    });

    if (!isLimitExceeded) {
      setUploadedFiles(uploaded);
      props.setFiles(uploaded);
    };
  }

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files)
    const allowedMimes = Object.values(mimeTypes);

    const uploadedFilesTypes = chosenFiles.map((file) => file.type);

    const hasInvalidFileType = uploadedFilesTypes.some((type) => !allowedMimes.includes(type));

    if (hasInvalidFileType) {
      e.preventDefault();
    } else {
      handleUploadFiles(chosenFiles);
    }
  }

  const handleDrop = (e) => {
    const allowedTypes = e.target.accept.split(',');
    const allowedMimes = allowedTypes.map((extension) => mimeTypes[extension]);
    const uploadedFilesTypes = Object.values(e.dataTransfer.files).map((file) => file.type);

    const hasInvalidFileType = uploadedFilesTypes.some((type) => !allowedMimes.includes(type));

    if (hasInvalidFileType) {
      e.preventDefault();
    }
  };

  const handleDeleteFile = (fileToDelete) => {
    const filteredFiles = uploadedFiles.filter((file) => file.name !== fileToDelete.name);
    setUploadDisabled(false);
    setUploadedFiles(filteredFiles);
    props.setFiles(filteredFiles);
  }

  return (
    <>
      <div className="file-upload">
        <p className="uploader-title">{props.placeholder}</p>
        <div className="uploader">
          <input
            id="fileUpload"
            aria-label="file"
            type="file"
            multiple
            accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.jpeg,.jpg,.png"
            onChange={handleFileEvent}
            className={`button ${!uploadDisabled ? '' : 'disabled'}`}
            onDrop={handleDrop}
            disabled={uploadDisabled}
            aria-required={false}
          />
          {uploadedFiles.length <= 0 && <p className="pt-5">No files chosen</p>}
          <div className="uploaded-files-list">
            {uploadedFiles.map((file) => (
              <div key={file.name} className="uploaded-file">
                {file.name}
                <button type="button" onClick={() => handleDeleteFile(file)} />
              </div>
            ))}
          </div>
        </div>
        {uploadDisabled && <p className="mt-0">{`You can only add a maximum of ${MAX_FILES_COUNT} files.`}</p>}
        <p>{props.label}</p>
        <p>{props.description}</p>
      </div>
    </>
  );
};

export default FileInput;
